import gql from 'graphql-tag'


export const CREATE_DETAIL = (templateType) => gql`mutation CREATE_DETAIL ($paperType: String!, $topicId: Int!, $input: ${templateType}TopicDetailInput!) {
  createDetail: create${templateType}TopicDetail (paperType: $paperType, topicId: $topicId, input: $input) {
    id content correction correctionResult
  }
}`

export const UPDATE_DETAIL = (templateType) => gql`mutation UPDATE_DETAIL ($paperType: String!, $detailId: Int!, $input: ${templateType}TopicDetailInput!) {
  updateDetail: update${templateType}TopicDetail (paperType: $paperType, detailId: $detailId, input: $input) {
    id content correction correctionResult
  }
}`

export const DESTROY_DETAIL = (templateType) => gql`mutation DESTROY_DETAIL ($paperType: String!, $detailId: Int!) {
  destroyDetail: destroy${templateType}TopicDetail (paperType: $paperType, detailId: $detailId) {id}
}`

export const MOVE_DETAIL = (templateType) => gql`mutation MOVE_DETAIL ($paperType: String!, $detailId: Int!, $step: Int!) {
  moveDetail: move${templateType}TopicDetail (paperType: $paperType, detailId: $detailId, step: $step) {id}
}`
