import gql from 'graphql-tag'


const listResponse = `
  id type code name isActive approvedBy
  docConfigTargetGraphs {id type docType name}
`

export const LIST_PAPER = (templateType) => gql`query LIST_PAPER ($paperType: String!, $q: FilterInput) {
  papers: list${templateType} (paperType: $paperType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type code name content isActive
  reviewedAt reviewedUser {id name}
  approvedAt approvedUser {id name}
`

export const DETAIL_PAPER = (templateType) => gql`query DETAIL_PAPER ($paperType: String!, $paperId: Int!) {
  paper: detail${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const CREATE_PAPER = (templateType) => gql`mutation CREATE_PAPER ($paperType: String!, $input: ${templateType}Input!) {
  paper: create${templateType} (paperType: $paperType, input: $input) {${detailResponse}}
}`

export const UPDATE_PAPER = (templateType) => gql`mutation UPDATE_PAPER ($paperType: String!, $paperId: Int!, $input: ${templateType}Input!) {
  paper: update${templateType} (paperType: $paperType, paperId: $paperId, input: $input) {${detailResponse}}
}`

export const APPROVE_SUBMIT_PAPER = (templateType) => gql`mutation APPROVE_SUBMIT_PAPER ($paperType: String!, $paperId: Int!, $submitDate: Date!) {
  paper: approveSubmit${templateType} (paperType: $paperType, paperId: $paperId, submitDate: $submitDate) {${detailResponse}}
}`

export const APPROVE_UNDO_PAPER = (templateType) => gql`mutation APPROVE_UNDO_PAPER ($paperType: String!, $paperId: Int!) {
  paper: approveUndo${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const REVIEW_SUBMIT_PAPER = (templateType) => gql`mutation REVIEW_SUBMIT_PAPER ($paperType: String!, $paperId: Int!, $submitDate: Date!) {
  paper: reviewSubmit${templateType} (paperType: $paperType, paperId: $paperId, submitDate: $submitDate) {${detailResponse}}
}`

export const REVIEW_UNDO_PAPER = (templateType) => gql`mutation REVIEW_UNDO_PAPER ($paperType: String!, $paperId: Int!) {
  paper: reviewUndo${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const DESTROY_PAPER = (templateType) => gql`mutation DESTROY_PAPER ($paperType: String!, $paperId: Int!) {
  paper: destroy${templateType} (paperType: $paperType, paperId: $paperId) {id}
}`
